import styled from "styled-components";
import { Button } from "../components/Button";
import { CenteredContainer } from "./CenteredContainer";

interface IntegrationLandingPageProps {
  integration: string;
  logo: string;
  onClick: () => void;
}

const LogoImage = styled.img`
  width: 50vw;
  height: auto;
  max-width: 100%;
  margin-bottom: 2vh;
`;

export function IntegrationLandingPage({
  integration,
  logo,
  onClick,
}: IntegrationLandingPageProps): JSX.Element {
  return (
    <CenteredContainer>
      <h1>Welcome to the {integration} Integration</h1>
      <LogoImage src={logo} alt="Integration Logo" />
      <p>Some explanation about what is going to happen...</p>
      <Button text={`Connect to ${integration}`} onClick={onClick} />
    </CenteredContainer>
  );
}

export default IntegrationLandingPage;
