import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppConfigProvider } from "../app-config/AppConfigProvider";
import { AuthProvider } from "../auth/AuthProvider";
import { GraphqlProvider } from "../graphql/GraphqlProvider";
import { IntegrationRouter } from "./IntegrationRouter";

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AppConfigProvider>
        <AuthProvider>
          <GraphqlProvider>
            <Routes>
              <Route path="/" Component={IntegrationRouter} />
            </Routes>
          </GraphqlProvider>
        </AuthProvider>
      </AppConfigProvider>
    </BrowserRouter>
  );
}

export default App;
