/* eslint-disable no-magic-numbers */
import { CSSProperties } from "react";
import styled from "styled-components";

interface Props {
  loading?: boolean;
  text: string;
  style?: CSSProperties;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick: () => void;
}

export const Button = ({
  loading,
  text,
  disabled: formInvalid = false,
  fullWidth = false,
  style,
  onClick,
}: Props): JSX.Element => {
  const disabled = formInvalid || loading;

  return (
    <StyledButton
      style={style}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
};

interface StyledButtonProps {
  fullWidth: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  border: 0;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.15),
    1px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  padding: 16px;

  margin-top: 16px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: green;

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
    `}

  ${(props) =>
    props.fullWidth &&
    `
      width: 100%;
    `}
`;
