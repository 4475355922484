import { useLocation } from "react-router-dom";
import IntegrationFlowMill from "../integrations/IntegrationFlowMill";
import IntegrationFlowZaptec from "../integrations/IntegrationFlowZaptec";

export const IntegrationRouter: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const integration = searchParams.get("integration")?.toLowerCase();
  const redirectUrl = searchParams.get("redirectUrl")?.toLowerCase() ?? "";

  const selectIntegration = (integration: string | undefined) => {
    switch (integration) {
      case "mill":
        return IntegrationFlowMill;
      case "zaptec":
        return IntegrationFlowZaptec;
      default:
        return null;
    }
  };

  const SelectedIntegration = selectIntegration(integration);

  if (SelectedIntegration) {
    return <SelectedIntegration redirectUrl={redirectUrl} />;
  } else {
    return <div>Invalid integration specified.</div>;
  }
};
