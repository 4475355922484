import React from "react";
import styled from "styled-components";
import { Button } from "./../components/Button";

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 5vw;
  box-sizing: border-box;
`;

const SuccessComponent: React.FC<{
  integration: string;
  redirectUrl: string;
}> = ({ integration, redirectUrl }) => {
  const redirectUrlWithProtocol = addProtocolIfMissing(redirectUrl);

  return (
    <CenteredContainer>
      <h1>Success!</h1>
      <p>You have successfully signed in to {integration}.</p>
      <p>Please return to the host mobile app.</p>
      {redirectUrlWithProtocol && (
        <Button
          text="Back"
          onClick={() => {
            window.location.replace(redirectUrlWithProtocol);
          }}
        />
      )}
    </CenteredContainer>
  );
};

export default SuccessComponent;

function addProtocolIfMissing(redirectUrl: string) {
  if (
    redirectUrl &&
    !redirectUrl.startsWith("http://") &&
    !redirectUrl.startsWith("https://")
  ) {
    redirectUrl = "https://" + redirectUrl;
  }
  return redirectUrl;
}
