import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { useLocation } from "react-router-dom";

export interface AuthContextValues {
  sessionToken: string;
}

const AuthContext = createContext<AuthContextValues | undefined>(undefined);

export const AuthProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [sessionTokenState, setSessionTokenState] = useLocalStorage<TokenState>(
    "SESSION_TOKEN",
    {
      type: TokenStateType.NOT_SET,
    }
  );

  const location = useLocation();

  useEffect(() => {
    // Get the session token from the query string
    const params = new URLSearchParams(location.search);
    const token = params.get("sessionToken");

    if (token) {
      setSessionTokenState({
        token,
        type: TokenStateType.VALID,
      });
    }
  }, [location, setSessionTokenState]);

  if (!sessionTokenState) {
    return <h1>Loading</h1>;
  }

  if (sessionTokenState.type === TokenStateType.VALID) {
    return (
      <AuthContext.Provider value={{ sessionToken: sessionTokenState.token }}>
        <>{children}</>
      </AuthContext.Provider>
    );
  }

  if (sessionTokenState.type === TokenStateType.INVALID) {
    return <h1>{`Invalid session token`}</h1>;
  }

  return <h1>Loading</h1>;
};

export const useAuth = (): AuthContextValues => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be called inside an AuthProvider`);
  }

  return context;
};

enum TokenStateType {
  VALID = "VALID",
  INVALID = "INVALID",
  NOT_SET = "NOT_SET",
}

interface ValidTokenState {
  type: TokenStateType.VALID;
  token: string;
}

interface InvalidTokenState {
  type: TokenStateType.INVALID;
}

interface NotSetTokenState {
  type: TokenStateType.NOT_SET;
}

type TokenState = ValidTokenState | InvalidTokenState | NotSetTokenState;
