import styled from "styled-components";
import { useState } from "react";
import { Button } from "../components/Button";
import { useCommonSignInMutation } from "../generated/graphql";
import LogoMill from "../assets/mill.png";
import IntegrationLandingPage from "../components/IntegrationLandingPage";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const IntegrationFlowMill: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [signIn, { loading }] = useCommonSignInMutation();
  const [showFlow, setShowFlow] = useState(false);

  const handleSignIn = () => {
    signIn({
      variables: {
        password,
        username,
      },
    })
      .then(() => {
        setSuccess(true);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
      });
  };

  return (
    <Form>
      {showFlow ? (
        <IntegrationLandingPage
          integration={"Mill"}
          logo={LogoMill}
          onClick={() => setShowFlow(true)}
        />
      ) : (
        <>
          <Input
            type="text"
            placeholder="Email"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button text="Sign In" onClick={handleSignIn} disabled={loading} />

          {success && !loading && <h1>✅</h1>}
          {error && !loading && <h1>❌</h1>}
        </>
      )}
      <Button
        text="Back"
        onClick={() => {
          setShowFlow(false);
          setPassword("");
          setUsername("");
          setSuccess(false);
          setError(false);
        }}
      />
    </Form>
  );
};

export default IntegrationFlowMill;
