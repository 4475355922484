import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useZaptecClaimInstallationMutationMutation } from "../generated/graphql";
import LogoZaptec from "../assets/zaptec.png";
import IntegrationLandingPage from "../components/IntegrationLandingPage";
import SuccessComponent from "../components/SuccessComponent";
import { getConfigForEnv } from "../app-config/AppConfig";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
`;

interface IntegrationFlowZaptecProps {
  redirectUrl: string;
}

export const IntegrationFlowZaptec: React.FC<IntegrationFlowZaptecProps> = ({
  redirectUrl,
}) => {
  const location = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const [installationId, setInstallationId] = useState<string | null>(null);

  const [claimInstallation] = useZaptecClaimInstallationMutationMutation();

  const handleClaimInstallation = async (installationId: string) => {
    setIsLoading(true);
    try {
      await claimInstallation({ variables: { installationId } });
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      console.error("Failed to claim installation: ", error);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const success = params.get("success");
    const sessionToken = params.get("sessionToken");
    const installationId = params.get("installationId");

    if (sessionToken) {
      setSessionToken(sessionToken);
    }
    if (installationId) {
      setInstallationId(installationId);
    }
    if (success === "true" && sessionToken && installationId) {
      handleClaimInstallation(installationId);
    }
  }, [location, claimInstallation]);

  const handleSignIn = () => {
    if (!sessionToken) {
      throw new Error("No session token");
    }
    const config = getConfigForEnv();

    const baseUrl = config.integrationAppUrl;

    const backToOurAppUrl = new URL(baseUrl);
    backToOurAppUrl.searchParams.append("integration", "zaptec");
    backToOurAppUrl.searchParams.append("redirectUrl", redirectUrl);
    backToOurAppUrl.searchParams.append("sessionToken", sessionToken);

    const oauthUrl = `${config.zaptecOauthBaseUrl}?partnerName=${
      config.zaptecPartnerName
    }&returnUrl=${encodeURIComponent(backToOurAppUrl.href)}`;

    window.location.href = oauthUrl;
  };

  return (
    <Form>
      {isLoading ? (
        <div>Loading...</div>
      ) : isSuccess ? (
        <SuccessComponent integration={"Zaptec"} redirectUrl={redirectUrl} />
      ) : (
        <IntegrationLandingPage
          logo={LogoZaptec}
          integration={"Zaptec"}
          onClick={handleSignIn}
        />
      )}
    </Form>
  );
};

export default IntegrationFlowZaptec;
